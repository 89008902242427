import React from "react";
import "./featuredNewTurismo.css";
import sandboard from "./../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/TURISMO/leon_city_08.jpg";
//import catedral from "./../../media/images/turismo/catedral.jpg";
import habitacion from "../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/007.jpg";
import desayuno from "../../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/desayuno-mini-chinese.jpg";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

const FeaturedNewTurismo = () => {

  const { t } = useTranslation();

  return (
    <section className="featuredTurismoNew">
      <article className="featuredItemTurismoNew">
        <img src={habitacion}
          alt="Visita la catedral de Leon"
          className="featuredImgNew" />
        <div className="featuredTitlesNew">
          <h1>
            {t("misc.lodging")}
          </h1>
          <Link to="/rooms">
            <button
              className="featuredBtnNew">
              {t("misc.more")}
            </button>
          </Link>
        </div>
      </article>
      <article className="featuredItemTurismoNew">
        <img src={sandboard}
          alt="SandBoarding en el Cerro negro"
          className="featuredImgNew" />
        <div className="featuredTitlesNew">
          <h1>
            {t("misc.tour")}
          </h1>
          <Link to="/tourList">
            <button
              className="featuredBtnNew">
              {t("misc.more")}
            </button>
          </Link>
        </div>
      </article>
      <article className="featuredItemTurismoNew">
        <img src={desayuno}
          alt="SandBoarding en el Cerro negro"
          className="featuredImgNew" />
        <div className="featuredTitlesNew">
          <h1>
            {t("misc.breakfast")}
          </h1>
          <Link to="/buffet">
            <button
              className="featuredBtnNew"
            >
              {t("misc.more")}
            </button>
          </Link>
        </div>
      </article>
    </section>
  );
};

export default withTranslation()(FeaturedNewTurismo);
