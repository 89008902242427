import img1 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow01.jpg';
import img2 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow02.jpg';
import img3 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow03.jpg';
import img4 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow04.jpeg';
import img5 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow05.jpg';
import img6 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow06.jpg';
import img7 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow07.jpg';
import img8 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow08.jpg';
import img9 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow09.jpg';
import img10 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow010.jpg';
import img11 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/slideshow011.jpeg';


export const fotosCuartos = [
  {
    id: 1,
    img: img1
  },
  {
    id: 2,
    img: img2
  },
  {
    id: 3,
    img: img3
  },
  {
    id: 4,
    img: img4
  },
  {
    id: 5,
    img: img5
  },
  {
    id: 6,
    img: img6
  },
  {
    id: 7,
    img: img7
  },
  {
    id: 8,
    img: img8
  },
  {
    id: 9,
    img: img9
  },
  {
    id: 10,
    img: img10
  },
  {
    id: 10,
    img: img11
  },
]
  /*
{dataDigitalBestSeller.map((item) =>(
<div classname="card">
<div classname="card-top">
<img src={item.src} alt="" />
</div>
<div classname="card-bottom">
</div>
</div>
))}

*/
