import React from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import "./buffet.css";
import { fotosDesayunos } from "../../assets/dataDesayunos";
import Galeria from "../../components/slideshows/Galeria";
import { useTranslation } from "react-i18next";
import { Head } from "../../layout/Head";
import RestaurantLogoNew from "../../assets/RestaurantLogoNew";

const Buffet = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Head title='Paola Restaurant | Hotel Casa Blanca' />
      <Navbar />
      <section className="hotelContainer">
        <article className="buffetWrapper">
          <RestaurantLogoNew />
          {/*
          <h1 className="hotelTitleBuffet">
            {t("BreakfastPage.brkfstTitle")}
          </h1>
          <h1 className="hotelTitle">
            {t("BreakfastPage.brkfstSubTitle")}
          </h1>
*/}
          <div className="buffetOtherCont">
            <div className="menuContainerChina">
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title1")}</h3>
              </div>
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title2")}</h3>
                <p className="itemChina">{t("restaurant.menuItem1")}</p>
                <p className="itemChina">{t("restaurant.menuItem2")}</p>
                <p className="itemChina">{t("restaurant.menuItem3")}</p>
                <p className="itemChina">{t("restaurant.menuItem4")}</p>
                <p className="itemChina">{t("restaurant.menuItem5")}</p>
                <p className="itemChina">{t("restaurant.menuItem6")}</p>
                <p className="itemChina">{t("restaurant.menuItem7")}</p>
                <p className="itemChina">{t("restaurant.menuItem8")}</p>
              </div>
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title3")}</h3>
                <p className="itemChina">{t("restaurant.menuItem9")}</p>
                <p className="itemChina">{t("restaurant.menuItem10")}</p>
                <p className="itemChina">{t("restaurant.menuItem11")}</p>
                <p className="itemChina">{t("restaurant.menuItem12")}</p>
              </div>
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title4")}</h3>
                <p className="itemChina">{t("restaurant.menuItem14")}</p>
                <p className="itemChina">{t("restaurant.menuItem15")}</p>
                <p className="itemChina">{t("restaurant.menuItem16")}</p>
                <p className="itemChina">{t("restaurant.menuItem17")}</p>
                <p className="itemChina">{t("restaurant.menuItem18")}</p>
              </div>
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title5")}</h3>
                <p className="itemChina">{t("restaurant.menuItem19")}</p>
                <p className="itemChina">{t("restaurant.menuItem20")}</p>
                <p className="itemChina">{t("restaurant.menuItem21")}</p>
                <p className="itemChina">{t("restaurant.menuItem22")}</p>
                <p className="itemChina">{t("restaurant.menuItem23")}</p>
                <p className="itemChina">{t("restaurant.menuItem24")}</p>
                <p className="itemChina">{t("restaurant.menuItem25")}</p>
                <p className="itemChina">{t("restaurant.menuItem26")}</p>
                <p className="itemChina">{t("restaurant.menuItem27")}</p>
                <p className="itemChina">{t("restaurant.menuItem28")}</p>
                <p className="itemChina">{t("restaurant.menuItem29")}</p>
                <p className="itemChina">{t("restaurant.menuItem30")}</p>
                <p className="itemChina">{t("restaurant.menuItem31")}</p>
                <p className="itemChina">{t("restaurant.menuItem32")}</p>
                <p className="itemChina">{t("restaurant.menuItem33")}</p>
                <p className="itemChina">{t("restaurant.menuItem34")}</p>
                <p className="itemChina">{t("restaurant.menuItem35")}</p>
                <p className="itemChina">{t("restaurant.menuItem36")}</p>
                <p className="itemChina">{t("restaurant.menuItem37")}</p>
              </div>
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title6")}</h3>
                <p className="itemChina">{t("restaurant.menuItem38")}</p>
                <p className="itemChina">{t("restaurant.menuItem39")}</p>
                <p className="itemChina">{t("restaurant.menuItem40")}</p>
                <p className="itemChina">{t("restaurant.menuItem41")}</p>
                <p className="itemChina">{t("restaurant.menuItem42")}</p>
              </div>
              <div className="miniMenuContainerChina">
                <h3 className="titleChina">{t("restaurant.title7")}</h3>
                <p className="itemChina">{t("restaurant.menuItem43")}</p>
                <p className="itemChina">{t("restaurant.menuItem44")}</p>
                <p className="itemChina">{t("restaurant.menuItem45")}</p>
                <p className="itemChina">{t("restaurant.menuItem46")}</p>
              </div>
            </div>
            <Galeria images={fotosDesayunos} />
          </div>
          {/*
          <div className="hotelDetails">
            <div className="hotelDetailsText">
              <p className="hotelDesc">
                Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
              </p>
              <p className="hotelDesc">
                Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
              </p>
              <p className="hotelDesc">
                Lorem cumque perspiciatis rem repellendus earum. Animi cum tempora delectus nemo dolor. Rerum natus sequi quis culpa laboriosam deleniti Natus exercitationem in debitis aliquid nisi? Explicabo asperiores sint omnis aperiam
</div>
              </p>
*/}
          {/*
<div className="hotelDetailsPrice">
<div className="siDetailsTexts">
<h1>Perfecto para una estadia lujosa</h1>
<h2>
<b className="siPrice">945 NIO</b> (familiar)
</h2>
<span className="siTaxOp">
Incluye iva
</span>
</div>
<div className="siServicios">
<div className="siServiciosLeft">
<p>
<FaWifi className="iconos" />
Wifi Gratis</p>
<p>
<FaSnowflake className="iconos" />
Aire Condicionado</p>
<p>
<FaShower className="iconos" />
Baño en la habitación</p>
</div>
<div className="siServiciosRight">
<p><FaParking className="iconos" />
Parqueo gratis</p>
<p><MdFreeBreakfast className="iconos" />
Desayuno</p>
<p><FaSmokingBan className="iconos" />
No Fumadores</p>
</div>
</div>
<button>Reserva Ahora</button>
</div>
</div>
<h1 className="homeTitle" style={{ paddingTop: "10px" }}> 
             Encuentra la habitación ideal 
           </h1> 
*/}
        </article>
        <Footer />
      </section>
    </div >
  );
};

export default Buffet;
