import React from "react";
import "./ubicacion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useTranslation, withTranslation } from "react-i18next";

const Ubicacion = () => {

  const { t } = useTranslation();

  return (
    <section className="ubicacion" id="ubicacion">
      <div className="ubicacionContainer">
        <h1 className="ubicacionTitle">
          <FontAwesomeIcon
            style={
              {
                paddingRight: "5px",
                fontSize: "26px",
              }
            }
            icon={faLocationDot}
          />
          {t("navbar.location")}
        </h1>
        <span className="ubicacionDescription">
          {t("home.address")}
        </span>
      </div>
      <iframe
        // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4769.088790797536!2d-86.8776958808801!3d12.440127214318276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f711f96f823df1b%3A0x578475427115f90a!2sHotel%20San%20Juan%20de%20Leon!5e0!3m2!1ses-419!2sni!4v1657825856533!5m2!1ses-419!2sni"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d685.1590476174106!2d-86.20149400409284!3d11.909892180210003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f7403a7671da2a7%3A0xa757fbc0d53c4bfb!2sCasa%20Blanca!5e0!3m2!1ses!2sni!4v1668626374576!5m2!1ses!2sni"
        title="Ubicacion Hotel Leon"
        style={
          {
            border: 0,
            width: "100%",
            height: "500px"
          }
        }
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade">
      </iframe>
    </section>
  );
};

export default withTranslation()(Ubicacion);
