import React from "react";
import "./footer.css";
import arcadius from "./../../media/images/logos/arcadius-logoweb-blanco.png"
//import {FaFacebook, FaInstagram, FaPhoneAlt, FaTwitter} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { BsFillArrowUpSquareFill, BsFillTelephoneFill, BsWhatsapp } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const Footer = () => {

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footerContainer">
        <div className="fLists">
          <ul className="flList">
            <li className="fListItem">
              <Link to="/rooms">
                {t("navbar.lodgings")}
              </Link>
            </li>
          </ul>
          <ul className="flList">
            <li className="fListItem">
              <Link to="/buffet">
                {t("navbar.breakfasts")}
              </Link>
            </li>
          </ul>
          <ul className="flList">
            <li className="fListItem">
              <Link to="/tourList">
                {t("navbar.tours")}
              </Link>
            </li>
          </ul>
          <ul className="flList">
            <li className="fListItem">
              <Link to="/ubicacion">
                {t("navbar.location")}
              </Link>
            </li>
          </ul>

          <ul className="flList">
            <li className="fListItem">
              <Link to="/contacto">
                {t("navbar.contact")}
              </Link>
            </li>
            <li className="fListItem">
              <a href="https://api.whatsapp.com/send?phone=50578726289&text=Hola,%20que%20tal?%deseo%20reservar%20una%20habitación" target="_blank" rel="noreferrer">
                <BsWhatsapp style={{ marginRight: '5px' }} />
                Whatsapp +505 7872 6289
              </a>
            </li>
            <li className="fListItem">
              <BsFillTelephoneFill style={{ marginRight: '5px' }} />
              Telefono +505 2535 3621
            </li>
            <li className="fListItem">
              <a href="mailto:info@hotelcasablanca.lat" target="_blank" rel="noreferrer">
                <AiOutlineMail style={{ marginRight: '5px' }} />
                <span>
                  info@hotelcasablanca.lat
                </span>
              </a>
            </li>

            <li className="fListItem">
              <a href="https://www.facebook.com/profile.php?id=100088520313873&mibextid=ZbWKwL" target="_blank" rel="noreferrer">
                <FaFacebookF style={{ marginRight: '5px' }} />
                facebook
              </a>
            </li>
            <li className="fListItem">
              <a href="https://instagram.com/hotelcasablanca.lat?igshid=Yzg5MTU1MDY=" target="_blank" rel="noreferrer">
                <FaInstagram style={{ marginRight: '5px' }} />
                instagram
              </a>
            </li>
          </ul>

        </div>

        {/*
<div className="fLists" style={{ paddingTop: "20px" }}>
          <ul className="flList">
            <li className="fListItem2">
              {t("footerComponent.conferences")}
            </li>
          </ul>
          <ul className="flList">
            <li className="fListItem2">
              {t("footerComponent.pool")}
            </li>
          </ul>
          <ul className="flList">
            <li className="fListItem2">
              {t("footerComponent.transportation")}
            </li>
          </ul>
        </div>
*/}

        <div className="fText">
          <span className="fTexto">
            Powered by
          </span>
          <a href="http://www.arcadiusnic.com/" target="_blank" rel="noreferrer">
            <img
              className="fLogo"
              src={arcadius}
              alt="Arcadius"
            />
          </a>
        </div>
      </div>
      <div
        className="subir"
        onClick={scrollTop}
      >
        <BsFillArrowUpSquareFill style={{ fontSize: '40px' }} />
      </div>
    </footer>
  );
};

export default withTranslation()(Footer);
