import img1 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/000-min.webp';
import img2 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/001-min.webp';
import img3 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/002-min.webp';
import img4 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/003-min.webp';
import img5 from '../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/DESAYUNO/004-min.webp';

export const fotosDesayunos = [
  {
    id: 101,
    img: img1
  },
  {
    id: 202,
    img: img2
  },
  {
    id: 303,
    img: img3
  },
  {
    id: 304,
    img: img4
  },
  {
    id: 305,
    img: img5
  },
]
  /*
{dataDigitalBestSeller.map((item) =>(
<div classname="card">
<div classname="card-top">
<img src={item.src} alt="" />
</div>
<div classname="card-bottom">
</div>
</div>
))}

*/
