import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './styles/styles.css'
import { DarkModeContextProvider } from "./context/darkModeContext";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";


i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: global_es
      },
      en: {
        translation: global_en
      }
    },
    lng: 'es',
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false
    }
  });



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
      </HelmetProvider>
    </DarkModeContextProvider>
  </React.StrictMode>
);
