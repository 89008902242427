import React from "react";
import Navbar from "./../../components/navbar/Navbar";
import './hotelList.css';
// import {useLocation} from "react-router-dom";
// import { format } from "date-fns";
// import { useNavigate } from "react-router-dom";
// import { DateRange } from "react-date-range";
// import SearchItem from "../../components/searchItem/SearchItem";
import Footer from "../../components/footer/Footer";
import CardMatrimonial from "../../components/cuartosCards/matrimonial/CardMatrimonial";
// import MatrimonialTranslation from "../../components/cuartosCards/matrimonial/CardMatrimonial";
import CardUnipersonal from "../../components/cuartosCards/unipersonal/CardUnipersonal";
import CardDoble from "../../components/cuartosCards/doble/CardDoble";
import CardTriple from "../../components/cuartosCards/triple/CardTriple";
// import CardTriple2 from "../../components/cuartosCards/triple/CardTirple2.js";
import CardCuadruple from "../../components/cuartosCards/cuadruple/CardCuadruple";
import { Head } from "../../layout/Head";


const HotelList = () => {

  /*
  const [destino] = useState("");
  const [abrirOpciones, setAbrirOpciones] = useState(false);
  const [opciones, setOpciones] = useState({
    adultos: 1,
    ninos: 0,
    cuarto: 1,
  });

  const navigate = useNavigate()

  const handleOption = (nombre, operacion) => {
    setOpciones(prev => {
      return {
        ...prev, [nombre]: operacion === "aumentar" ? opciones[nombre] + 1 : opciones[nombre] - 1,
      }
    })
  }

  const handleSearch = () => {
    navigate("/rooms", { state: { destino, date, opciones } })
  }

  const location = useLocation();
  const [destino, setDestino] = useState(location.state.destino);
  const [date, setDate] = useState(location.state.date);
  const [opciones, setOpciones] = useState(location.state.opciones);

  const [openDate, setOpenDate] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);


  */
  return (
    <div className="listMain">
      <Head title='Habitaciones | Hotel Casa Blanca' />
      <Navbar />
      <div className="listContainer">
        <div className="listWraper">

          {/*		Busqueda para version Movil 
          <div className="listSearchMobile">
            <div className="headerSearchMobile">
              <div className="headerSearchItemMobile">
                <FontAwesomeIcon icon={faCalendarDays} className="headerIcon" onClick={() => setOpenDate(!openDate)} />
                <span onClick={() => setOpenDate(!openDate)} className="headerSearchText">{`Check in ${format(date[0].startDate, "dd/MM/yyyy")} Check Out ${format(date[0].endDate, "dd/MM/yyyy")}`}</span>
                {openDate &&
                  <DateRange
                    editableDateInputs={true}
                    onChange={item => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    className="date"
                    minDate={new Date()}
                  />}
              </div>
              <div className="headerSearchItemMobile">
                <FontAwesomeIcon icon={faPerson} className="headerIcon" />
                <span
                  onClick={() => setAbrirOpciones(!abrirOpciones)}
                  className="headerSearchText">{`${opciones.adultos} adultos - ${opciones.ninos} niños - ${opciones.cuarto} cuarto`}
                </span>
                {abrirOpciones &&
                  <div className="options">
                    <div className="optionItem">
                      <span className="optionText">
                        Adultos
                      </span>
                      <div className="optionCounter">
                        <button
                          disabled={opciones.adultos <= 1}
                          className="optionCounterBtn"
                          onClick={() => handleOption("adultos", "disminuir")}>
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {opciones.adultos}
                        </span>
                        <button
                          className="optionCounterBtn"
                          onClick={() => handleOption("adultos", "aumentar")}>
                          +
                        </button>
                      </div>
                    </div>
                    <div className="optionItem">
                      <span className="optionText">
                        Niños
                      </span>
                      <div className="optionCounter">
                        <button
                          disabled={opciones.ninos <= 0}
                          className="optionCounterBtn"
                          onClick={() => handleOption("ninos", "disminuir")}>
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {opciones.ninos}
                        </span>
                        <button
                          className="optionCounterBtn"
                          onClick={() => handleOption("ninos", "aumentar")}>
                          +
                        </button>
                      </div>
                    </div>
                    <div className="optionItem">
                      <span className="optionText">
                        Cuarto
                      </span>
                      <div className="optionCounter">
                        <button
                          disabled={opciones.cuarto <= 1}
                          className="optionCounterBtn"
                          onClick={() => handleOption("cuarto", "disminuir")}>
                          -
                        </button>
                        <span className="optionCounterNumber">
                          {opciones.cuarto}
                        </span>
                        <button
                          className="optionCounterBtn"
                          onClick={() => handleOption("cuarto", "aumentar")}>
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="headerSearchItemMobile">
                <button
                  className="headerBtnSearch"
                  onClick={handleSearch}>
                  Buscar
                </button>
              </div>
            </div>
          </div>
            */}

          {/*		Busqueda para version Movil

          <div className="listSearch">
            <h1 className="lsTitle">Buscar</h1>
            <div className="lsItem">
              <label>Fecha de estadía:</label>
              <span
                className="checkin"
                onClick={() => setOpenDate(!openDate)}
              >
                {`${format(date[0].startDate, "dd/MM/yyyy")} al ${format(date[0].endDate, "dd/MM/yyyy")}`}
              </span>
              {openDate && (
                <DateRange
                  onChange={item => setDate([item.selection])}
                  className="lsDate"
                  minDate={new Date()}
                  ranges={date}
                />
              )}
            </div>
            <div className="lsItem">
              <label>Opciones</label>
              <div className="lsOptions">
                <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Precio Minimo <br /><small>por noche</small>
                  </span>
                  <input className="lsOptionInput" type="number" min={200} placeholder="200 NIO" />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Precio Máximo <br /><small>por noche</small>
                  </span>
                  <input className="lsOptionInput" type="number" min={200} placeholder="3000 NIO" />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Adultos
                  </span>
                  <input className="lsOptionInput" type="number" min={1} placeholder="1" />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Niños
                  </span>
                  <input className="lsOptionInput" type="number" min={0} placeholder="0" />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">
                    Cuarto
                  </span>
                  <input className="lsOptionInput" type="number" min={1} placeholder="1" />
                </div>
              </div>
            </div>
            <button>
              <b>
                Buscar
              </b>
            </button>
          </div>
 */}
          <section className="listResult">
            {/* <MatrimonialTranslation /> */}
            <CardMatrimonial />
            <CardUnipersonal />
            <CardDoble />
            <CardTriple />
            {/*
            <CardTriple2 />
            */}
            <CardCuadruple />
          </section>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HotelList;
