import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faCircleArrowRight, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
// import FeaturedTurismo from "../../components/featuredTurismo/FeaturedTurismo";
// import FeaturedRooms from "../../components/featuredRooms/FeaturedRooms";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
//import MailList from "../../components/mailList/MailList";
//import PropertyList from "../../components/propertyList/PropertyList";
import Ubicacion from "../../components/ubicacion/Ubicacion";
import NavbarNew from "./../../components/newNavbar/NavbarNew";
import "./home.scss";
import "../../components/featuredSliderRoom/featuredSliderRoom.css";
import FeaturedNewTurismo from "../../components/featuredNewTurismo/featuredNewTurismo";
// import FeaturesSliderRoom from "../../components/featuredSliderRoom/featuresSliderRoom";
import { fotosCuartos } from "../../assets/dataPortadaSlideshow";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useTranslation, withTranslation } from "react-i18next";
import { Head } from "../../layout/Head";

const Home = () => {

  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (index) => {
    // console.log("clicked")
    setSlideNumber(index);
    setOpen(true);
  }

  const handleMove = (direction) => {
    let newSliderNumber;

    if (direction === "left") {
      newSliderNumber = slideNumber === 0 ? 10 : slideNumber - 1
    } else {
      newSliderNumber = slideNumber === 10 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSliderNumber);
  }


  const { t } = useTranslation();
  return (
    <div>
      <Head title='Hotel Casa Blanca' />
      {open &&
        <div className="slider">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="close"
            onClick={() => setOpen(false)}
          />
          <FontAwesomeIcon
            icon={faCircleArrowLeft}
            className="arrow"
            onClick={() => handleMove("left")}
          />
          <div className="sliderWrapper" >
            <img
              src={fotosCuartos[slideNumber].img}
              className="sliderImg"
              alt="" />
          </div>
          <FontAwesomeIcon
            icon={faCircleArrowRight}
            className="arrow"
            onClick={() => handleMove("right")}
          />
        </div>
      }
      <NavbarNew />
      <Header />
      <div className="homeContainer">
        {/*
<h1 className="homeTitle" style={{paddingTop:"10px"}}>
Encuentra la habitación ideal
</h1>
        */}
        {/*
        <FeaturedRooms />
        <FeaturesSliderRoom slides={fotosCuartos} />
*/}

        {/*
                <h1 className="homeTitle">
          Tours en la zona de León</h1>
        <FeaturedTurismo />
      */}

        <section className="sliderWraper">
          <Splide
            options={{
              rewind: true,
              gap: '5px',
              perPage: 4,
            }}
            aria-label="My Favorite Images">
            {fotosCuartos.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <img
                    src={item.img}
                    onClick={() => handleOpen(index)}
                    className="cardImg"
                    alt="Hotel San Juan de Leon" />
                </SplideSlide>
              )
            })}
          </Splide>
        </section>

        <h1 className="homeTitle">
          {t("home.homeTitle1")}
        </h1>
        <FeaturedNewTurismo />
        <Ubicacion />
        {/*
<PropertyList />
<MailList />
        */}
        <Footer />
      </div>
    </div>
  );
};

export default withTranslation()(Home);
