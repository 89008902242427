// Matrimonial
// import mat01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial01.jpg";
// import mat02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial02.jpg";
// import mat03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial03.jpg";
// import mat04 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/MATRIMONIAL/cardMatrimonial04.jpg";
// // Unipersonal
// import uni01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/UNI-PERSONAL/cardUni01.jpg";
// import uni02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/UNI-PERSONAL/cardUni02.jpg";
// import uni03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/UNI-PERSONAL/cardUni03.jpg";
// Doble
import doble01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/007.jpg";
import doble02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/008.jpg";
import doble03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/PORTADA/009.jpg";
// Triple
// import triple01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple01.jpg";
// import triple02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple02.jpg";
// import triple03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple03.jpg";
// import triple04 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/TRIPLE/triple04.jpg";
// // Cuadruple
// import cuad01 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad00.jpg";
// import cuad02 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad01.jpg";
// import cuad03 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad02.jpg";
// import cuad04 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad03.jpg";
// import cuad05 from "../media/images/MULTIMEDIA-HOTEL-SAN-JUAN-LEON/HABITACIONES/CUADRUPLE/cuad04.jpg";


const fotosMatrimonial = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
  {
    id: 3,
    img: doble03
  },
]

const fotosUnipersonal = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
  {
    id: 3,
    img: doble03
  },
]

const fotosDoble = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
  {
    id: 3,
    img: doble03
  },
]

const fotosTriple = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
]

const fotosTriple2 = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
]

const fotosCuadruple = [
  {
    id: 1,
    img: doble01
  },
  {
    id: 2,
    img: doble02
  },
  {
    id: 3,
    img: doble03
  },
]

export { fotosMatrimonial, fotosUnipersonal, fotosDoble, fotosTriple, fotosTriple2, fotosCuadruple };
